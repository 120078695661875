import React, { useState, useEffect, useCallback } from "react";
import { Button } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Swal from "../../swal";
import { sendMultiPartData, sendData, getData } from "../../../helpers/auth";
import { uriList } from "../../../helpers/config";
import { Pagination, FilterSelect, InputLabelAlert } from "../../layout";
import {
  formatFloat,
  formatCurrency,
  operacaoList,
  width
} from "../../../helpers/general";

let statuses = [
  { value: "ATIVO", nome: "Ativo" },
  { value: "INATIVO", nome: "Inativo" },
];

const swalOptions = {
  title: "Deseja realmente excluir o registro selecionado?",
  icon: "warning",
  buttons: {
    cancel: {
      text: "Não",
      value: null,
      visible: true,
      className: "",
      closeModal: true,
    },
    confirm: {
      text: "Sim",
      value: true,
      visible: true,
      className: "bg-danger",
      closeModal: true,
    },
  },
};

let _competencia = [
  { value: -1, descricao: "Não" },
  { value: 1, descricao: "Sim" },
];

const Form = ({ ...props }) => {
  let {
    filterOptions,
    handleOption,
    tabelas,
    actualPage,
    handlePage,
    setClickedTr,
    sortArrObj,
    clickedTr,
    callback,
    setTabelas,
    ocultarinativas,
    setOcultarInativas,
  } = props;

  const [show, setShow] = useState(false);
  const [id, setId] = useState(0);
  const [nome, setNome] = useState("");
  const [convenio_id, setConvenioId] = useState(props.convenio_id || 0);
  const [jurosm, setJurosm] = useState(0);
  const [jurosa, setJurosa] = useState(0);
  const [iof, setIOF] = useState(0);
  const [seguro, setSeguro] = useState(0);
  const [tac, setTAC] = useState(0);
  const [method, setMethod] = useState("POST");
  const [gravando, setGravando] = useState(false);
  const [codigo, setCodigo] = useState("");
  const [codigoTab, setCodigoTab] = useState("");
  const [operacao, setOperacao] = useState("CONTRATO NOVO");
  const [status, setStatus] = useState("ATIVO");
  const [codigo_refin, setCodigoRefin] = useState("");
  const [codigo_novoport, setCodigoNovoport] = useState("");
  const [variacao_port, setVariacaoPort] = useState(0);
  const [competencia, setCompetencia] = useState(-1);
  const [taxa_refinorg_ini, setTaxaRefinorgIni] = useState(0);
  const [taxa_refinorg_fin, setTaxaRefinorgFin] = useState(0);
  const [taxa, setTaxa] = useState(0);
  const [procreditoid, setProcreditoId] = useState(0);
  const [repescagemCodigo, setRepescagemCodigo] = useState('')
  const [repescagemCodigoRefin, setRepescagemCodigoRefin] = useState('')
  const [empresas, setEmpresas] = useState(null);
  const [empresaId, setEmpresaId] = useState(props.user.empresa_id)

  const handleClose = () => setShow(false);
  const handleEdit = async (id) => {
    getData({ uri: uriList("tabelas") + "/" + id })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();

        setId(id);
        setNome(res.nome);
        setConvenioId(res.convenio_id);
        setJurosm(res.jurosm);
        setJurosa(res.jurosa);
        setIOF(res.iof);
        setTAC(res.tac);
        setSeguro(res.seguro);
        setCodigo(res.codigo);
        setCodigoTab(res.codigotab)
        setOperacao(res.operacao);
        setStatus(res.status);
        setCodigoRefin(res.codigo_refin);
        setCodigoNovoport(res.codigo_novoport);
        setVariacaoPort(res.variacao_port);
        setCompetencia(res.competencia_dias);
        setTaxaRefinorgIni(res.taxa_refinorg_ini)
        setTaxaRefinorgFin(res.taxa_refinorg_fin)
        setProcreditoId(res.procreditoid)
        setTaxa(res.taxa)
        setRepescagemCodigo(res.repescagem_codigo)
        setRepescagemCodigoRefin(res.repescagem_codigonovo)
        setEmpresaId(res.empresa_id)

        setMethod("PUT");
        setShow(true);
      })
      .catch((e) => {
        return false;
      });
  };

  const _empresas = () => {
    console.log("Tabelas._empresas(useCallback)")
    getData({ uri: uriList("empresas") })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();

        setEmpresas(res);
      })
      .catch((e) => {
        return false;
      });
  };
  const handleDelete = async (isConfirm, swal, id) => {
    if (isConfirm) {
      await sendData({
        uri: uriList("tabelas") + "/" + id,
        content: {},
        method: "DELETE",
      })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) throw new Error();
          callback();
        })
        .catch((e) => {
          alert("Erro ao excluir Tabela.");
        });
    }
  };

  const handlePost = async () => {
    setGravando(true);
    let dObj = new FormData();

    dObj.append("nome", nome);
    dObj.append("convenio_id", convenio_id);
    dObj.append("jurosm", formatFloat(jurosm, 4));
    dObj.append("jurosa", formatFloat(jurosa, 4));
    dObj.append("iof", formatFloat(iof, 4));
    dObj.append("seguro", formatFloat(seguro, 4));
    dObj.append("tac", formatFloat(tac));
    dObj.append("codigo", codigo);
    dObj.append("codigotab", codigoTab);
    dObj.append("operacao", operacao);
    dObj.append("status", status);
    dObj.append("codigo_refin", codigo_refin);
    dObj.append("codigo_novoport", codigo_novoport);
    dObj.append("repescagem_codigo", repescagemCodigo);
    dObj.append("repescagem_codigonovo", repescagemCodigoRefin);
    dObj.append("variacao_port", formatFloat(variacao_port));
    dObj.append("competencia_dias", competencia);
    if (parseInt(procreditoid) > 0)
      dObj.append("procreditoid", procreditoid);
    dObj.append("taxa_refinorg_ini", formatFloat(taxa_refinorg_ini));
    dObj.append("taxa_refinorg_fin", formatFloat(taxa_refinorg_fin));
    dObj.append("taxa", formatFloat(taxa));
    dObj.append("empresa_id", empresaId);

    await sendMultiPartData({
      uri: uriList("tabelas") + (method === "PUT" ? "/" + id : ""),
      content: dObj,
      method,
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200)
          throw new Error(res.message[0].message);
        callback();
        setShow(false);
        setGravando(false);
      })
      .catch((e) => {
        setGravando(false);
        alert(e);
      });
  };

  const handleNew = () => {
    setId(0);
    setNome("");
    setConvenioId(0);
    setJurosm(0);
    setJurosa(0);
    setIOF(0);
    setSeguro(0);
    setTAC(0);
    setCodigo("");
    setCodigoTab("");
    setOperacao("CONTRATO NOVO");
    setCodigoRefin("");
    setCodigoNovoport("");
    setVariacaoPort(0);
    setCompetencia(-1);
    setTaxaRefinorgIni(0);
    setTaxaRefinorgFin(0);
    setProcreditoId(0);
    setTaxa(0)
    setRepescagemCodigo('')
    setRepescagemCodigoRefin('')

    setMethod("POST");
    setShow(true);
  };

  const handleChange = (e, userData) => {
    console.log("Tabelas - handleChange", e.currentTarget);
    let target = e.currentTarget;
    let name = target.name;
    let value = userData || target.value;

    if (name == "empresa_id") {
      setEmpresaId(value)
    }
    else if (name == "jurosm") {
      value = formatCurrency(value, 4);
      setJurosm(value);
    } else if (name == "jurosa") {
      value = formatCurrency(value, 4);
      setJurosa(value);
    } else if (name == "variacao_port") {
      value = formatCurrency(value, 4);
      setVariacaoPort(value);
    } else if (name == "iof") {
      value = formatCurrency(value, 4);
      setIOF(value);
    } else if (name == "tac") {
      value = formatCurrency(value, 4);
      setTAC(value);
    } else if (name == "seguro") {
      value = formatCurrency(value, 4);
      setSeguro(value);
    }
    else if (name == "taxa_refinorg_ini") {
      value = formatCurrency(value, 4);
      setTaxaRefinorgIni(value);
    }
    else if (name == "taxa_refinorg_fin") {
      value = formatCurrency(value, 4);
      setTaxaRefinorgFin(value);
    }
    else if (name == "taxa") {
      value = formatCurrency(value, 4);
      setTaxa(value);
    }
    else if (name == "procreditoid") {
      setProcreditoId(value?.replace(/\D/g, ""));
    }
  };

  const handleBlur = (e, userData) => {
    let target = e.currentTarget;
    let name = target.name;
    let value = userData || target.value;

    if (name == "jurosm") {
      setJurosm(formatCurrency(value, 4));
    } else if (name == "jurosa") {
      setJurosa(formatCurrency(value, 4));
    } else if (name == "variacao_port") {
      setVariacaoPort(formatCurrency(value));
    } else if (name == "tac") {
      setTAC(formatCurrency(value));
    } else if (name == "iof") {
      setIOF(formatCurrency(value, 4));
    } else if (name == "seguro") {
      setSeguro(formatCurrency(value, 4));
    }
    else if (name == "taxa_refinorg_ini") {
      setTaxaRefinorgIni(value);
    }
    else if (name == "taxa_refinorg_fin") {
      setTaxaRefinorgFin(value);
    }
    else if (name == "taxa") {
      setTaxa(value);
    }
    else if (name == "procreditoid") {
      setProcreditoId(value);
    }
  };

  useEffect(() => {
    _empresas();
  }, [])

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Cadastro de Tabelas</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth750">
          {gravando ? (
            <div>Gravando registro...</div>
          ) : (
            <div className="form" style={{ overflow: "hidden" }}>
              <div className="row">
                <div className="col-md-3 col-3">
                  <label className="m-0 font-90">Status</label>
                  <select
                    className="form-control"
                    defaultValue={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    {statuses.map((item, key) => {
                      return (
                        <option key={key} value={item.value}>
                          {item.nome}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-md-3 col-3">
                  <label className="m-0 font-90">Código</label>
                  <input
                    type="text"
                    className="form-control"
                    autoFocus
                    value={codigoTab}
                    onChange={(e) =>
                      setCodigoTab(e.target.value && e.target.value.toUpperCase())
                    }
                  />
                </div>
                {props.user.gestor && empresas &&
                  <div className="col-md-6">
                    <label className="m-0 font-90">Empresa</label>
                    <select
                      id={"empresa_id"}
                      name={"empresa_id"}
                      required={true}
                      defaultValue={empresaId}
                      className="form-control"
                      onChange={handleChange}
                    >
                      {empresas &&
                        empresas.map((item, key) => {
                          return (
                            <option key={key} value={item.id} selected={empresaId == item.id}>
                              {item.nome + (width() != 'mobile' ? ' - ' + item.cnpj : "")}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                }
              </div>

              <label className="m-0 font-90">Nome</label>
              <input
                type="text"
                className="form-control"
                autoFocus
                value={nome}
                onChange={(e) =>
                  setNome(e.target.value && e.target.value.toUpperCase())
                }
              />

              <div className="row">
                <div className="col-md-4 col-4">
                  <label className="m-0 font-90">Código/Sigla - Banco</label>
                  <input
                    type="text"
                    className="form-control"
                    value={codigo}
                    onChange={(e) => setCodigo(e.target.value.toUpperCase())}
                  />
                </div>

                <div className="col-md-4 col-4">
                  <label className="m-0 font-90">Código Refin(Port)</label>
                  <input
                    type="text"
                    className="form-control"
                    value={codigo_refin}
                    onChange={(e) =>
                      setCodigoRefin(e.target.value.toUpperCase())
                    }
                  />
                </div>

                <div className="col-md-4 col-4">
                  <label className="m-0 font-90">Cód Simulação(Port)</label>
                  <input
                    type="text"
                    className="form-control"
                    value={codigo_novoport}
                    onChange={(e) =>
                      setCodigoNovoport(e.target.value.toUpperCase())
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 col-3">
                  <label className="m-0 font-90">Repescagem(Port)</label>
                  <input
                    type="text"
                    className="form-control"
                    value={repescagemCodigo}
                    onChange={(e) =>
                      setRepescagemCodigo(e.target.value.toUpperCase())
                    }
                  />
                </div>

                <div className="col-md-3 col-3">
                  <label className="m-0 font-90">Repescagem(Refin)</label>
                  <input
                    type="text"
                    className="form-control"
                    value={repescagemCodigoRefin}
                    onChange={(e) =>
                      setRepescagemCodigoRefin(e.target.value.toUpperCase())
                    }
                  />
                </div>
                <div className="col-md-3 col-3">
                  <InputLabelAlert
                    type="tel"
                    label="Juro Mensal"
                    id="jurosm"
                    cifrao={false}
                    value={jurosm}
                    step="0.10"
                    handle={handleChange}
                    onChange={jurosm}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>

                <div className="col-md-3 col-3">
                  <InputLabelAlert
                    type="tel"
                    label="Juro Anual"
                    id="jurosa"
                    cifrao={false}
                    value={jurosa}
                    step="0.10"
                    handle={handleChange}
                    onChange={jurosa}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 col-3">
                  <InputLabelAlert
                    type="tel"
                    label="IOF"
                    id="iof"
                    cifrao={false}
                    value={iof}
                    step="0.10"
                    handle={handleChange}
                    onChange={iof}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>

                <div className="col-md-3 col-3">
                  <InputLabelAlert
                    type="tel"
                    label="Seguro"
                    id="seguro"
                    cifrao={false}
                    value={seguro}
                    step="0.10"
                    handle={handleChange}
                    onChange={seguro}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
                <div className="col-md-3 col-3">
                  <InputLabelAlert
                    type="tel"
                    label="Taxa Refin Inicial"
                    id="taxa_refinorg_ini"
                    cifrao={false}
                    value={taxa_refinorg_ini}
                    step="0.10"
                    handle={handleChange}
                    onChange={taxa_refinorg_ini}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>

                <div className="col-md-3 col-3">
                  <InputLabelAlert
                    type="tel"
                    label="Taxa Refin Final"
                    id="taxa_refinorg_fin"
                    cifrao={false}
                    value={taxa_refinorg_fin}
                    step="0.10"
                    handle={handleChange}
                    onChange={taxa_refinorg_fin}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
              </div>


              <div className="row">
                <div className="col-md-3 col-3">
                  <InputLabelAlert
                    type="tel"
                    label="Variação Portabilidade"
                    id="variacao_port"
                    cifrao={false}
                    value={variacao_port}
                    step="0.10"
                    handle={handleChange}
                    onChange={variacao_port}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>

                <div className="col-md-3 col-3">
                  <InputLabelAlert
                    type="tel"
                    label="Tarifa"
                    id="tac"
                    cifrao={false}
                    value={tac}
                    step="0.10"
                    handle={handleChange}
                    onChange={tac}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
                <div className="col-md-3 col-3">
                  <label className="m-0 font-90">Operação</label>
                  <select
                    className="form-control"
                    defaultValue={operacao}
                    onChange={(e) => setOperacao(e.target.value)}
                  >
                    {operacaoList.map((item, key) => {
                      return (
                        <option key={key} value={item.value}>
                          {item.descricao}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-md-3 col-3">
                  <label className="m-0 font-90">Virada Folha(Competência)</label>
                  <select
                    className="form-control"
                    defaultValue={competencia}
                    title="Virada da folha de pagamento?"
                    onChange={(e) => setCompetencia(e.target.value)}
                  >
                    {_competencia.map((item, key) => {
                      return (
                        <option key={key} value={item.value}>
                          {item.descricao}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-6">
                  <label className="m-0 font-90">Convênio</label>
                  <select
                    className="form-control"
                    defaultValue={convenio_id}
                    onChange={(e) => setConvenioId(e.target.value)}
                  >
                    {props.convenios &&
                      props.convenios.map((item, key) => {
                        if (!convenio_id) {
                          setConvenioId(item.id);
                          console.log(convenio_id);
                        }
                        return (
                          <option key={key} value={item.id}>
                            {item.nome}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="col-md-6 col-6">
                  <InputLabelAlert
                    type="numeric"
                    label="ProcreditoID"
                    id="procreditoid"
                    cifrao={false}
                    value={procreditoid}
                    handle={handleChange}
                    onChange={procreditoid}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button color="success" onClick={handlePost}>
            Gravar
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="col-md-4 py-1 px-0">
        <button
          onClick={() => handleNew()}
          className="btn btn-sm text-light"
          title="Novo Tabela"
          style={{ backgroundColor: "#00bdff", marginRight: "3px" }}
        >
          <i className="mdi mdi-plus-box" />
        </button>
        <button
          onClick={() => window.print()}
          className="btn btn-sm text-light"
          title="Imprimir"
          style={{ backgroundColor: "#00bdff" }}
        >
          <i className="mdi mdi-printer" />
        </button>
      </div>

      <div className="row py-2">
        <div className={`col-md-2`}>
          <label className="m-0 font-90">Tabela</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="nome"
          />
        </div>

        <div className={`col-md-2`}>
          <label className="m-0 font-90">Convênio</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="convenio_id"
          />
        </div>
        <div className={`col-md-2`}>
          <label className="m-0 font-90">Ocultar Intativas</label>
          <FilterSelect
            detailed={{ ocultarinativas: ["Sim", "Nao"], }}
            handleOption={handleOption}
            ocultarTodos={true}
            value={ocultarinativas}
            oLabel="ocultarinativas"
            title={"Ocultar Tabelas inativas"}
          />
        </div>
        <div className={`col-md-6`}>
          <InputLabelAlert
            className="m-0 font-90"
            type="text"
            label="Tabela/Convênio"
            id="tabela_filter"
            cifrao={false}
            value={props.tabela_filter}
            handle={props.handleTabela}
            focus={true}
          />
        </div>
      </div>

      {/* <div className='table-responsive'> */}
      <table className="table table-bordered table-striped">
        <thead>
          <tr
            title="Clique para ordenar"
            style={{ backgroundColor: "#f2f2f2" }}
          >
            <th scope="col" className="font-80">
              #
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("nome", "string")}
            >
              Tabela
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("convenio_id", "string")}
            >
              Convênio
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("status", "string")}
            >
              Status
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("status", "string")}
            >
              Empresa
            </th>
            <th
              scope="col text-right"
              className="font-80"
              onClick={() => sortArrObj("id", "integer")}
            >
              TabelaID
            </th>

            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {tabelas.length > 0 ? (
            tabelas.map(
              (Tabela, i) =>
                i >= actualPage * 10 &&
                i < (actualPage + 1) * 10 && (
                  <tr
                    key={i}
                    className={`${clickedTr === Tabela.id
                      ? "bg-secondary text-light"
                      : "" + Tabela.status == "INATIVO"
                        ? " mark"
                        : ""
                      }`}
                    onClick={() => {
                      setClickedTr(Tabela.id);
                    }}
                    onDoubleClick={() => {
                      handleEdit(Tabela.id);
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setClickedTr(Tabela.id);
                    }}
                  >
                    <td>{i + 1}</td>
                    <td>{Tabela.nome}</td>
                    <td>{Tabela.convenio}</td>
                    <td>{Tabela.status}</td>
                    <td style={{ minWidth: "250px", maxWidth: "250px" }}>
                      {Tabela.empresa}
                    </td>
                    <td style={{ minWidth: "40px", maxWidth: "40px" }}>
                      {Tabela.id}
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-sm btn-warning"
                        title="Altera um registro"
                        onClick={(e) => handleEdit(Tabela.id)}
                      >
                        <i className="mdi mdi-file-edit-outline"></i>
                      </button>
                      <Swal
                        options={swalOptions}
                        id={Tabela.id}
                        title="Exclusão de Registro"
                        callback={handleDelete}
                        className="btn btn-sm btn-danger"
                        style={{ marginLeft: "2px", cursor: "pointer" }}
                      >
                        <i className="mdi mdi-delete"></i>
                      </Swal>
                    </td>
                  </tr>
                )
            )
          ) : (
            <tr>
              <td>Sem dados</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2" className="text-left">
              Total de Tabelas: {tabelas && tabelas.length}
            </td>

            <td colSpan="7"></td>
          </tr>
        </tfoot>
      </table>
      <Pagination
        detailed={tabelas}
        actual={actualPage}
        changePage={handlePage}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Form);


