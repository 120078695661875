import React, { useState, useEffect, useCallback } from "react";

import Form from "../../components/form/admin/Usuarios/usuarios";

import { getData, sendData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";
import {
  DefaultOrder,
  sortByString,
  sortByNumber,
  compareDate,
} from "../../helpers/general";

function Usuarios({ ...props }) {
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosC, setUsuariosC] = useState(null);
  const [total, setTotal] = useState(false);
  const [actualPage, setActualPage] = useState(0);
  const [filterOptions, setFilterOptions] = useState(false);
  const [dateVisible, setDateVisible] = useState("todos");
  const [modal, setModal] = useState(false);
  const [clickedTr, setClickedTr] = useState("");
  const [ascDesc, setAscDesc] = useState(true);
  const [usuario_filter, setUsuarioFilter] = useState("");
  const [ocultarInativos, setOcultarInativos] = useState(localStorage.getItem("ocultarInativos") || "todos");
  const [master, setMaster] = useState("todos");
  const [tipo, setTipo] = useState("todos");
  const [clear, setClear] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [selecaoEmpresa_id, setSelecaoEmpresa_id] = useState(null);
  const [qtdLinesPage, setQtdLinesPage] = useState(10)


  const handleQtdLinesPage = (e) => {
    const value = e?.currentTarget?.value
    console.log("usuarios.handleQtdLinesPage: ", value)
    setQtdLinesPage(value);
  }


  useEffect(() => {
    getUsuarios();
  }, [qtdLinesPage])

  const handlePage = (e) => {
    setActualPage(e.currentTarget.value);
  };

  const createFilter = (obj) => {
    // console.log("views.createFilter(1)");
    let newObj = {
      nome: [],
      sigla: [],
    };

    for (let o of obj) {
      for (let i in o) {
        if (newObj[i]) {
          newObj[i].push(o[i]);
        }
      }
    }

    for (let i in newObj) {
      newObj[i] = [...new Set(newObj[i])];
    }

    setFilterOptions(newObj);
  };

  const getUsuarios = () => {
    console.log("views.getUsuarios(1): ", { setSelecaoEmpresa_id, selecaoEmpresa_id, })
    // if (!firstOpen)
    setLoaded(false)

    sendData({
      uri: uriList("usuarios"), content: {
        ocultarInativos,
        master,
        tipo,
        usuario_filter: usuario_filter?.toUpperCase() || "",
        actualPage: actualPage + 1,
        qtdLinesPage,
        selecaoEmpresa_id: selecaoEmpresa_id?.toString() == "todos" ? null : selecaoEmpresa_id,
      }, method: "POST"
    })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        let usuarios = (res?.data || res)
        console.log("views.getUsuarios(2): ", usuarios)
        if (!usuariosC)
          setUsuariosC(usuarios)

        DefaultOrder(
          "usuarios",
          (usuarios)?.map((usuarios) => usuarios),
          setUsuarios
        );
        // createFilter((res?.data || res).map((usuarios) => usuarios));
        setLoaded(true)
      })
      .catch((e) => {
        return false;
      });

    // sendData({
    //   uri: uriList("usuarios"), content: {
    //     ocultarInativos: "sim",
    //   }, method: "POST"
    // })
    //   .then((res) => {
    //     if (res.status && res.status > 200) throw new Error();
    //     setUsuariosC(res?.data || res);
    //     setLoaded(true)
    //   })
    //   .catch((e) => {
    //     return false;
    //   });
  };

  useEffect(() => {
    console.log("views.usuarios.useEffect(1)");

    //-- Busca todos os usuários para setar os filtros de usuarios na edição/criação de usuários(master, supervisor, etc)
    getUsuarios();
  }, [clear]);

  const sortArrObj = (key, type) => {
    console.log("views.sortArrObj(1)");
    let arr = [...usuarios];
    let asc = !ascDesc;

    arr = arr.sort((a, b) => {
      if (type === "string") {
        if (asc) {
          setAscDesc(asc);
          return sortByString(a, b, key, type);
        } else {
          setAscDesc(asc);
          return sortByString(b, a, key, type);
        }
      } else if (type === "numeric") {
        if (asc) {
          setAscDesc(asc);
          return sortByNumber(a, b, key, type);
        } else {
          setAscDesc(asc);
          return sortByNumber(b, a, key, type);
        }
      } else {
        if (asc) {
          setAscDesc(asc);
          return compareDate(new Date(a[key]), new Date(b[key])) ? 1 : -1;
        } else {
          setAscDesc(asc);
          return compareDate(new Date(b[key]), new Date(a[key])) ? 1 : -1;
        }
      }
    });

    console.log("views.sortArrObj(2): ", arr)
    setUsuarios(arr);
  };

  const handleUsuario = (e) => {
    let value = e.currentTarget.value;
    // console.log("views.handleUsuario(1): ", value)
    setUsuarioFilter(value);
    // handleOption(e);
  };

  const handleInativos = (e) => {
    let value = e.currentTarget.value;
    // console.log("views.handleOcultarInativos(1): ", value)
    setOcultarInativos(value);
    localStorage.setItem("ocultarInativos", value);
    // handleOption(e);
  };

  const handleSelecaoEmpresa = (e) => {
    let value = e.currentTarget.value;
    // console.log("views.handleOcultarInativos(1): ", value)
    setSelecaoEmpresa_id(value);
    localStorage.setItem("setSelecaoEmpresa_id", value);
    // handleOption(e);
  };

  const handleMaster = (e) => {
    let value = e.currentTarget.value;
    // console.log("views.handleUsuario(1): ", value)
    setMaster(value);
    // handleOption(e);
  };

  const handleTipo = (e) => {
    let value = e.currentTarget.value;
    // console.log("views.handleTipo(1): ", { value, id: e.id })
    setTipo(value);
    // handleOption(e);
  };


  const handleClear = () => {
    // console.log("views.handleClear(1)");
    setUsuarioFilter("");
    setOcultarInativos("todos");
    setMaster("todos");
    setTipo("todos");
    localStorage.setItem("ocultarInativos", 'todos');

    setClear(!clear);
  };

  return (
    <>
      <Form
        filterOptions={filterOptions}
        // handleOption={handleOption}
        setDateVisible={setDateVisible}
        setModal={setModal}
        setClickedTr={setClickedTr}
        clickedTr={clickedTr}
        sortArrObj={sortArrObj}
        // setUsuarios={setUsuarios}
        usuarios={usuarios}
        usuariosG={usuariosC}
        actualPage={actualPage}
        handlePage={handlePage}
        dateVisible={dateVisible}
        total={total}
        handlePesquisar={getUsuarios}
        getUsuarios={getUsuarios}
        context={props.context}
        handleUsuario={handleUsuario}
        usuario_filter={usuario_filter}
        handleInativos={handleInativos}
        ocultarInativos={ocultarInativos}
        tipo={tipo}
        handleTipo={handleTipo}
        master={master}
        handleMaster={handleMaster}
        handleClearOptions={handleClear}
        loaded={loaded}
        setSelecaoEmpresa_id={handleSelecaoEmpresa}
        selecaoEmpresa_id={selecaoEmpresa_id}
        qtdLinesPage={qtdLinesPage}
        handleQtdLinesPage={handleQtdLinesPage}
      />

      {modal}
    </>
  );
}

export default Usuarios;
