import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { setUser } from '../../../store/actions/dados';
import { connect } from 'react-redux';
import { TOKEN_BANK, TOKEN_KEY, TOKEN_USER } from '../../../helpers/config';
import PerfilUsuario from '../../form/admin/Usuarios/perfilUsuario';

// avatar images
import avatar1 from '../../../images/users/avatar-1.png';
import { width } from '../../../helpers/general';

const ProfileMenu = ({ alterarUser }) => {
  const [menu, setMenu] = useState(false);
  const [perfil, setPerfil] = useState(false);

  const handleLogout = async () => {
    console.log("[PROFILEMENU_LOGOUT]")
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_USER);
    localStorage.removeItem(TOKEN_BANK)
    localStorage.clear();
    alterarUser(null);
  };

  const toggle = () => {
    setMenu(!menu);
  };

  const togglePerfil = () => {
    console.log("[PROFILEMENU_PERFIL]: ", perfil)
    setPerfil(!perfil);
  };

  return (
    <>
      {perfil && <PerfilUsuario onCloseEv={togglePerfil} />}
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="list-inline-item dropdown notification-list ml-1"
        tag="li"
      >
        <DropdownToggle
          className="nav-link arrow-none waves-effect nav-user"
          tag="a"
          href="#"
        >
          <img
            src={avatar1}
            alt="user"
            style={{ opacity: '20%' }}
            className="rounded-logo"
          />
        </DropdownToggle>
        <DropdownMenu className="profile-dropdown" right>
          <DropdownItem>
            <Link to="#" onClick={(e) => { e.preventDefault(); togglePerfil(); }}>
              <span style={{ fontSize: width() === "mobile" ? "10px" : "" }}>
                <i className="mdi mdi-account-edit"></i>
                Perfil
              </span>
            </Link>
          </DropdownItem>

          <DropdownItem divider />

          <DropdownItem>
            <Link to="/login" onClick={handleLogout}>
              <span style={{ fontSize: width() === "mobile" ? "10px" : "" }}>
                <i className="mdi mdi-location-exit"></i>
                Logout
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarUser(novoUser) {
      dispatch(setUser(novoUser));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(ProfileMenu);

// import React, { Component, useEffect } from "react";
// import { Link } from "react-router-dom";
// import {
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap";
// import { setUser } from "../../../store/actions/dados";
// import { connect } from "react-redux";
// import { TOKEN_KEY, TOKEN_USER } from "../../../helpers/config";
// import PerfilUsuario from "../../form/admin/Usuarios/perfilUsuario";

// // avatar images
// import avatar1 from "../../../images/users/avatar-1.png";
// import { width } from "../../../helpers/general";

// class ProfileMenu extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       menu: false,
//       perfil: false
//     };
//     this.toggle = this.toggle.bind(this);
//   }

//   handleLogout = async () => {
//     localStorage.removeItem(TOKEN_KEY);
//     localStorage.removeItem(TOKEN_USER);
//     localStorage.clear();
//     this.props.alterarUser(null);
//   };

//   handlePerfil = async () => {

//   }

//   toggle() {
//     this.setState((prevState) => ({
//       menu: !prevState.menu,
//     }));
//   }

//   togglePerfil() {
//     this.setState((prevState) => ({ perfil: !prevState.perfil, }));
//   }

//   render() {
//     // console.log("Usuário:", )
//     return (
//       <>
//         {this.state.perfil && <PerfilUsuario onCloseEv={this.togglePerfil} />}
//         <Dropdown
//           isOpen={this.state.menu}
//           toggle={this.toggle}
//           className="list-inline-item dropdown notification-list ml-1"
//           tag="li"
//         >
//           <DropdownToggle
//             className="nav-link arrow-none waves-effect nav-user"
//             tag="a"
//             href="#"
//           >

//             <img
//               src={avatar1}
//               alt="user"
//               style={{ opacity: "20%", }}
//               // className="rounded-circle"
//               className="rounded-logo"
//             />
//           </DropdownToggle>
//           <DropdownMenu className="profile-dropdown" right>
//             <DropdownItem>
//               <Link to="/login" onClick={(e) => this.handleLogout()}>
//                 <span style={{ fontSize: width() == "mobile" ? "10px" : "" }}>
//                   <i className="mdi mdi-location-exit"></i>
//                   Logout
//                 </span>
//               </Link>
//             </DropdownItem>
//             <DropdownItem>
//               <Link to="#" onClick={(e) => this.togglePerfil()}>
//                 <span style={{ fontSize: width() == "mobile" ? "10px" : "" }}>
//                   <i className="mdi mdi-account-edit"></i>
//                   Perfil
//                 </span>
//               </Link>
//             </DropdownItem>
//           </DropdownMenu>
//         </Dropdown>
//       </>
//     );
//   }
// }

// // export default ProfileMenu;
// function mapStateToProps(state) {
//   return {
//     user: state.dados.user,
//     proposta: state.dados.proposta,
//   };
// }

// function mapActionToProps(dispatch) {
//   return {
//     alterarUser(novoUser) {
//       dispatch(setUser(novoUser));
//     },
//   };
// }

// export default connect(mapStateToProps, mapActionToProps)(ProfileMenu);
