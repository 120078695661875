import React, { useState, useEffect } from "react";

import Form from "../../components/form/simulator/financedata";
import SelPort from "../../components/form/simulator/selPort";
import Result from "./result";
import { bancosCNPJ } from "../../helpers/bancos";
import { clienteBloqueado } from "../../helpers/core";

import { Buttons } from "../../components/layout";
import { swalError } from "../../components/swal";
import {
  getSession,
  getData,
  sendData,
  buscarAutorizacao,
  bancoFornecedor,
} from "../../helpers/auth";

import { uriList } from "../../helpers/config";
import { goBack } from "../../helpers/core";
import {
  formatCurrency,
  formatFloat,
  mCPF,
  getCookie,
  numerosIndividuais,
} from "../../helpers/general";
import { validate, validateCPF } from "../../helpers/validation";
import { connect } from "react-redux";

function FinanceData({ ...props }) {
  let initialState = {
    cpf: "",
    saldo_devedor: 0,
    valor_liquido: 0,
    parcelas_total: 0,
    parcela_reembolso: 0,
    parcela_original: 0,
    convenio_id: 0,
    tabela_id: 0,
    prazo: 0,
    valor_parcela: 0,
    numero_portabilidade: "",
    proposta_uuid: props.proposta,
    ip: getCookie({ label: "ip" }),
    ispb: "",
  };

  let alertIni = {
    saldo_devedor: 0,
    valor_liquido: 0,
    parcelas_total: 0,
    parcela_reembolso: 0,
    parcela_original: 0,
    valor_parcela: 0,
    contrato_portado: 0,
    parcela_portada: 0,
    banco_portado: 0,
    prazo: 0,
    tabela_id: 0,
  };

  const [msg, setMsg] = useState({ cpf: 0 });
  const [data, setData] = useState(initialState);
  const [automatico, setAutomatico] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [simulando, setSimulando] = useState(false);
  const [simulacao, setSimulacao] = useState(false);
  const [result, setResult] = useState({});
  const [dados, setDados] = useState({});
  const [selPort, setSelPort] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [convenioList, setConvenioList] = useState([]);
  const [tabelaList, setTabelaList] = useState([]);
  const [prazoSteps, setPrazoSteps] = useState(null);
  const [exibir_contratos_port, setExibir_contratos_port] = useState(false);
  const [naoGravar, setNaoGravar] = useState(false);
  const [disableCPF, setDisableCPF] = useState(false);
  const [autorizacaoInvalida, setAutorizacaoInvalida] = useState(false);
  const [selectedBanco, setSelectedBanco] = useState()

  const [showAlert, setAlert] = useState(alertIni);


  async function Validate() {
    // console.log("financedata - Validate") 
    setAlert(alertIni);
    let errorMsg = "Preencha os campos obrigatórios";
    let _alert = alertIni
    let result = true;

    if (!data.valor_parcela) {
      result = false;
      _alert = { ..._alert, valor_parcela: 1 };
    }

    if (!data.parcelas_total) {
      result = false;
      _alert = { ..._alert, parcelas_total: 1 };
    }

    //-- Comentado à pedido do cliente
    // if ((bancoFornecedor() == "inbursa" && data.parcelas_total > 72)) {
    //   result = false;
    //   // swalError("Parcelas restantes superior ao permitido");
    //   errorMsg = "Parcelas restantes superior ao permitido";
    //   _alert = { ..._alert, parcelas_total: 1 };
    //   // throw new Error("Parcelas superior ao permitido para o INBURSA")
    // }

    if (!data.saldo_devedor) {
      result = false;
      _alert = { ..._alert, saldo_devedor: 1 };
    }

    if (!data.cpf || !validateCPF(data.cpf)) {
      result = false;
      _alert = { ..._alert, cpf: 1 };
    }

    if (!exibir_contratos_port) {
      if (!data.contrato_portado) {
        result = false;
        _alert = { ..._alert, contrato_portado: 1 };
      }

      if (!data.parcela_portada) {
        result = false;
        _alert = { ..._alert, parcela_portada: 1 };
      }

      if (!data.banco_codigo) {
        result = false;
        _alert = { ..._alert, banco_portado: 1 };
      }

      if (!data.tabela_id) {
        result = false;
        _alert = { ..._alert, tabela_id: 1 };
      }

      if (!data.prazo) {
        result = false;
        _alert = { ..._alert, prazo: 1 };
      }
    }

    if (!result) {
      setAlert(_alert)
      console.log("financedata - Validate: ", _alert)
      swalError(errorMsg);
    }

    return result;
  }

  async function handleConvenio(convenio_id) {
    setAlert(alertIni);
    setConvenio_id(convenio_id);
    _getTabelas(convenio_id);
  }

  async function _getTabelas(convenio_id) {
    // console.log("_getTabelas: ", convenio_id)
    setTabelaList([]);

    handleTabela(0);
    if (convenio_id) {
      // 12.08.2023 - Teste
      // setCarregando(true);
      await sendData({
        uri: uriList("listarTabelas"),
        content: { convenio_id, operacao: "PORTABILIDADE" },
        method: "POST",
      })
        .then((res) => {
          setCarregando(false);
          if (res.status && parseInt(res.status) > 200) throw new Error();
          // console.log("Listou tabelas: ", res)
          setTabelaList(res);
          if (res && res[0] && res[0].id) handleTabela(res[0].id);
        })
        .catch((e) => {
          setCarregando(false);
          alert("Erro ao obter Tabelas.\n" + e.message);
        });
    }
  }

  const handleTabela = (tabela_id) => {
    setAlert(alertIni);
    // console.log("HandleTabela: ", tabela_id)
    setTabela_id(tabela_id);
    _getPrazos(tabela_id);
  };

  const handlePrazo = (prazo) => {
    setAlert(alertIni);
    setPrazo(prazo);
  };

  async function _getPrazos(tabela_id) {
    setPrazoSteps([]);
    if (tabela_id) {
      //-- teste 12.08.2023
      // setCarregando(true);
      getData({ uri: uriList("prazos") + "/" + tabela_id })
        .then((res) => {
          setCarregando(false);
          if (res.status && res.status > 200) throw new Error();

          setPrazoSteps(res);
          setPrazo(res[0].prazo);
        })
        .catch((e) => {
          setCarregando(false);
          return false;
        });
    }
  }

  function handleSelPort(contrato) {
    console.log("handleSelPort: ", contrato);
    simular(contrato);
    setSelPort(false);
  }

  async function simular(contrato) {
    console.log("financeData - simular");
    let newData = {};
    setNaoGravar(!contrato && props.portabilidade);

    if (!data.valor_parcela) return;

    setSimulacao(true);
    newData.cpf = data.cpf.replace(/\D/g, "");
    newData.proposta_uuid = props.proposta
    newData.valor = formatFloat(data.valor_parcela);
    newData.parcelas = formatFloat(data.valor_parcela);
    newData.convenio_id = data.convenio_id;
    newData.tabela_id = data.tabela_id;
    newData.prazo = data.prazo;
    newData.operacao = "PORTABILIDADE";
    newData.tipo = "parcela";
    newData.dadosPort = {
      ...data,
      saldo_devedor: formatFloat(data.saldo_devedor),
      valor_liquido: 0,
      parcela_reembolso: formatFloat(data.parcela_reembolso),
      valor_parcela: formatFloat(data.valor_parcela),
      parcelas_total: data.parcelas_total,
      banco_cnpj: contrato?.nrCnpj || data.banco_cnpj,
      contrato: contrato?.nrContrato || data.contrato_portado,
      banco_nome: contrato?.nmConsignataria || data.banco_nome,
      validade: contrato?.dtValidade,
      orgao: contrato?.codOrgao,
      convenio: contrato?.cdConvenio,
      parcela_original: formatFloat(contrato.vlParcelaPort || data.parcela_portada),
      ispb: data.ispb,
      nome: contrato?.nome,
      inicioPort: props.portabilidade,
      agente_id: ["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) >= 0 ? props.user?.pessoa_id : null,
      operador_id: props.user?.pessoa_id,
    };

    console.log("Simulando Portabilidade: ", newData);
    sendData({
      uri: uriList("simulador"),
      content: newData,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200) {
          setSimulacao(false);
          swalError({ title: "Erro: " + res.erro });
          return;
        }

        res.tipo = newData.tipo;
        setResult(res);
        setDados(newData);
        setSimulando(true);
        setSimulacao(false);
      })
      .catch((e) => {
        let erro = JSON.stringify(e);
        setSimulacao(false);
        console.log("Erro ao simular Portabilidade(Diogo 14.01.2022): ", erro);
        swalError({ title: "Erro ao simular operação!\n" + erro });
      });
  }

  async function submit() {
    let autorizacao = await buscarAutorizacao({
      cpf: data.cpf,
      convenio_id: data.convenio_id,
      tipo_cnvenio: data.tipo_convenio
    });
    console.log("buscando autorização: ", autorizacao);
    if (!autorizacao) {
      setSimulacao(false);
      setAutorizacaoInvalida(true);
      swalError("Não existe autorização para o cliente!");
      return;
    }

    // const allowed = await clienteBloqueado(data.cpf);
    // if (!allowed) {
    //   setSimulacao(false);
    //   swalError({ title: "Operação não poderá ser iniciada, favor entrar em contato com o Banco/Financeira." });
    //   return;
    // }

    if (props.portabilidade && await Validate()) {
      setSimulando(false);
      setEnviando(true);
    }

    let newData = Object.assign(data);
    setSimulacao(false);

    newData.portabilidade = props.portabilidade;
    newData.proposta_uuid = props.proposta;
    newData.saldo_devedor = formatFloat(newData.saldo_devedor);
    newData.valor_liquido = formatFloat(newData.valor_liquido);
    newData.parcela_reembolso = formatFloat(newData.parcela_reembolso);
    newData.parcela_original = formatFloat(newData.parcela_original);
    newData.valor_parcela = formatFloat(newData.valor_parcela);
    newData.cpf = data.cpf.replace(/\D/g, "");

    console.log("Submit- Dados de Complemento: ", newData);
    if (data.saldo_devedor && data.saldo_devedor > 0) {
      sendData({
        uri: uriList("propostacmp"),
        content: newData,
        method: "POST",
      })
        .then((res) => {
          setEnviando(false);
          if (res.status && parseInt(res.status) > 200) throw new Error();

          if (props.toggleStep) props.toggleStep(1, res);
        })
        .catch((error) => {
          setEnviando(false);
          console.log("Erro: ", error);
        });
    }
    return false;
  }

  function toggleStep(n) {
    console.log(`toggleStep: ${n}`);
    if (n == 1) {
      if (props.toggleStep) props.toggleStep(1);
    } else setSimulando(false);
  }

  function setConvenio_id(convenio_id) {
    console.log("financedata - setConvenio_id: ", convenio_id);
    let _data = data;
    let _convenio = {};
    console.log("financedata - convenios: ", convenioList);

    for (let i = 0; i < convenioList.length; i++) {
      _convenio = convenioList[i];
      console.log("_convenio: ", _convenio);
      if (_convenio.id == convenio_id) {
        console.log(
          "handleConvenio: (exibir_contratos_port)",
          _convenio.exibir_contratos_port
        );
        setExibir_contratos_port(_convenio?.exibir_contratos_port?.toLowerCase() == "sim");
        if (_convenio?.exibir_contratos_port?.toLowerCase() == "sim")
          setData({ ...data, contrato_portado: '', parcela_portada: 0, })

        break;
      }
    }

    _data.convenio_id = convenio_id;
    // console.log("setConvenio_id: ", _data);
    setData(_data);
  }

  function setTabela_id(tabela_id) {
    let _data = data;
    _data.tabela_id = tabela_id;

    setData(_data);
  }

  function setPrazo(prazo) {
    let _data = data;
    _data.prazo = prazo;
    // console.log("setPrazo: ", _data);
    setData(_data);
  }

  function handleChange(e, innerData) {
    setAlert(alertIni);
    let target = e.currentTarget;

    if (
      ["saldo_devedor", "parcela_original", "valor_parcela", "parcela_portada"].indexOf(
        target.name
      ) >= 0
    )
      setData({
        ...data,
        [target.name]: formatCurrency(innerData || target.value),
      });
    else if (target.name == "contrato_portado")
      setData({ ...data, [target.name]: target.value?.replace(/\D/g, "") });
    else setData({ ...data, [target.name]: target.value });

    // console.log("handleChange: ", target.name)
    // console.log("handleChange: (Dados)", data)
  }

  const handleCpf = async (e) => {
    setAutorizacaoInvalida(false);
    setAlert(alertIni);
    setDisableCPF(false);
    setMsg({ ...msg, cpf: 0 });
    let nums = /\d/;
    let cpf = e.currentTarget.value;
    let valid = true;
    if (nums.test(e.currentTarget.value)) {
      cpf = mCPF(e.currentTarget.value);
      setData({ ...data, cpf });
      if (cpf.length >= 14) {
        valid = validateCPF(cpf);
        if (!valid) setMsg({ ...msg, cpf: 1 });
        else {
          await sendData({
            uri: uriList("consultarRestricoes"),
            content: { cpf },
            method: "POST",
          })
            .then((res) => {
              if (res.status && parseInt(res.status) > 200) {
                setDisableCPF(true);
                console.log("Erro no CPF: ", res);
                swalError({ title: res.message });
                return false;
              }
            })
            .catch((error) => {
              console.log("Erro ao consultar Restrições: ", error);
            });
        }
      }

      return false;
    } else if (!e.currentTarget.value) setData({ ...data, cpf: "" });

    return false;
  };

  const handleBanco = (e) => {
    setAlert(alertIni);
    console.log("financedata.HandleBanco(0): ", e);
    let _banco = e;
    console.log("financedata.HandleBanco(1): ", _banco);
    if (_banco) {
      let _data = { ...data, banco_codigo: _banco.value, banco_cnpj: _banco.cnpj, banco_nome: _banco.label, ispb: _banco.ispb };
      console.log("financedata.HandleBanco(2): ", _data);
      setData(_data);
      setSelectedBanco(e)
    }

    // let target = e.currentTarget;
    // console.log("financedata.HandleBanco: ", target.value);
    // if (target.value) {
    //   let _banco = bancosCNPJ.filter((b) => b.value == target.value)[0];
    //   let _data = { ...data, banco_codigo: _banco.value, banco_cnpj: _banco.cnpj, banco_nome: _banco.nome, ispb: _banco.ispb };
    //   console.log("financedata.HandleBanco(_data): ", _data);
    //   setData(_data);
    // }
  }

  useEffect(() => {
    console.log("financedata - useEffect(1)");
    console.log("financedata (simulator) - useEffect: ", props.portabilidade);
    if (props.portabilidade && !convenioList?.length) {
      // teste 12.08.2023
      // setCarregando(true);
      // sendData({ uri: uriList("convenios") })
      sendData({
        uri: uriList("listarConvenios"),
        content: { retirarTabelas: true },
        method: "POST",
      })
        .then((res) => {
          setCarregando(false);
          if (res.status && res.status > 200) throw new Error();
          if (!Array.isArray(res)) throw new Error();
          setConvenioList(res);
          if (res && res.length == 1) {
            console.log("Diogo Teste: ", res[0].exibir_contratos_port);
            setExibir_contratos_port(res[0]?.exibir_contratos_port?.toLowerCase() == "sim");
            if (res[0]?.exibir_contratos_port?.toLowerCase() == "sim")
              setData({ ...data, contrato_portado: '', parcela_portada: 0, })
          }

          if (res && res[0]) handleConvenio(res[0].id);
        })
        .catch((e) => {
          setCarregando(false);
          setConvenioList([]);
        });
    }

    // 12.08.2023 - Soh busca registro se existir proposta
    if (props.proposta) {
      setAlert(alertIni);
      setSimulacao(false);
      getData({ uri: uriList("propostacmp") + "/" + props.proposta })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error(res.message);

          // console.log("Resultado: ", res)
          setAutomatico(res.automatico);
          for (let d in data) {
            if (res[d]) {
              if (
                [d] != "parcelas_total" &&
                [d] != "id" &&
                [d] != "proposta_uuid"
              )
                setData((previous) => ({
                  ...previous,
                  [d]: formatCurrency(formatFloat(res[d])),
                }));
              else setData((previous) => ({ ...previous, [d]: res[d] }));
            }
          }
        })
        .catch((e) => {
          setData(initialState);
          console.log("Erro ao obter dados complementares da proposta!");
          console.log("Dados: ", data);
          let storeData = getSession({ key: "bancarios" });

          if (storeData) setData(storeData);
        });
    }
    // eslint-disable-next-line
  }, []);

  function onBlur(e) {
    setAlert(alertIni);
    let target = e.currentTarget;

    if (target.name === "saldo_devedor" && target.value) {
      let saldo = formatFloat(data.saldo_devedor);
      let valor = formatFloat(props.detalhes.valor_liquido);
      setData({
        ...data,
        valor_liquido: formatCurrency(formatFloat(valor - saldo)),
      });
    } else if (target.name === "valor_liquido" && target.value) {
      let valorLiq = formatFloat(data.valor_liquido);
      let valor = formatFloat(props.detalhes.valor_liquido);
      setData({
        ...data,
        saldo_devedor: formatCurrency(formatFloat(valor - valorLiq)),
      });
    }
  }

  async function calcularSaldoDevedor() {
    console.log("[financedata.calcularSaldoDevedor]: ", data);
    // if (parseFloat(data.saldo_devedor) <= 0)
    if (parseFloat(data.parcela_portada) > 0)
      await sendData({
        uri: uriList("calcularSaldoDevedor"),
        content: { ...data },
        method: "POST",
      })
        .then((res) => {
          setCarregando(false);
          if (res.status && parseInt(res.status) > 200) throw new Error();
          console.log("[financedata.calcularSaldoDevedor(result)]: ", res);
          const _saldoDevedor = formatCurrency(formatFloat(res?.data?.saldoCalculado || 0));
          setData({ ...data, saldo_devedor: _saldoDevedor });
        })
        .catch((e) => {
          setCarregando(false);
          alert("Erro ao calcular saldo devedor.\n" + e.message);
        });
  }

  return (
    <>
      {selPort && exibir_contratos_port && (
        <SelPort
          cpf={data.cpf}
          goBack={() => setSelPort(false)}
          exibir_contratos_port={exibir_contratos_port}
          submit={handleSelPort}
        />
      )}
      {!selPort && simulando && (
        <Result
          title="Simulação"
          toggleStep={toggleStep}
          result={dados}
          data={result}
          admin={true}
          naoGravar={naoGravar}
          simulacao={simulacao}
          disabledSecond={naoGravar}
          dadosPort={data}
          portabilidade={props.portabilidade}
        />
      )}

      {!selPort && !simulando && !enviando && (
        <fieldset className="col-12">
          <div id="simulation-financedata">
            <h4
              className="display-4 text-center font-weight-bold"
              style={{ marginBottom: "15px", color: "#3E6C84" }}
            >
              {props.title}
            </h4>

            <Form
              data={data}
              change={handleChange}
              warning={showAlert}
              detalhes={props.detalhes}
              onBlur={onBlur}
              automatico={automatico}
              portabilidade={props.portabilidade}
              setConvenio_id={setConvenio_id}
              setTabela_id={setTabela_id}
              setPrazo={setPrazo}
              enviando={enviando}
              handleCpf={handleCpf}
              msg={msg}
              simulacao={simulacao}
              convenioList={convenioList}
              tabelaList={tabelaList}
              prazoSteps={prazoSteps}
              // carregando={carregando}
              handleConvenio={handleConvenio}
              handleTabela={handleTabela}
              handlePrazo={handlePrazo}
              handleBanco={handleBanco}
              handleChange={handleChange}
              exibir_contratos_port={exibir_contratos_port}
              selectedBanco={selectedBanco}
              calcularSaldoDevedor={calcularSaldoDevedor}
            />


            <Buttons
              goBack={() => {
                setAlert(alertIni);
                setSimulacao(false);
                goBack(data, "financeiro", props.toggleStep);
              }}
              submit={async () => {
                setAlert(alertIni);
                if (props.portabilidade) {
                  if (await Validate()) {
                    // setSelPort(true);
                    if (exibir_contratos_port)
                      setSelPort(true);
                    else
                      handleSelPort(data)
                  }
                } else submit();
              }}
              disabledSecond={disableCPF || autorizacaoInvalida}
            />
          </div>
        </fieldset>
      )}
      {!selPort && enviando && (
        <div style={{ textAlign: "center", width: "100%" }}>
          <br />
          <h2 style={{ opacity: "60%" }}>Aguarde, gravando dados...</h2>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    detalhes: state.dados.detalhes,
  };
}

export default connect(mapStateToProps)(FinanceData);
