import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Main from "./routes/forms";
import PreencherCli from "./routes/preencherCli";
import NovaSenha from "./routes/novaSenha";
import ProgressStatus from "./routes/status";
import NoMatch from "./routes/404";
import Login from "./routes/login";
import Refin from "./routes/refinanciamento";
import Admin from "./routes/admin";
import { connect } from "react-redux";
import { setUser, setNotificacoes, } from "./store/actions/dados";
import AdminRoute from "./routes/auth";
import { uriList } from "./helpers/config";
import { getCookie, saveCookie } from "./helpers/general";
import { inactivityTime, gravarIp, getItemCrypt, setItemCrypt } from "./helpers/auth";
import Ws from "@adonisjs/websocket-client";

function App({ ...props }) {
  function excluirLocks() {
    // sendData({ uri: uriList('excluirlocks'), method: 'POST' })
  }

  console.log("App.js");
  console.log("App(debug): ", process.env.REACT_APP_DEBUG);
  console.log("App.js - TESTE DE LOG DE VERSÃO: ", process.env.REACT_APP_VERSION);
  //-- Desabilitando console.log da aplicação  
  // if (!process.env.REACT_APP_DEBUG) console.log = () => { };
  // if (_root.indexOf("http://127.0.0.1") < 0 && _root.indexOf("localhost") < 0) console.log = () => { };

  function novaProposta() {
    props.setNotificacoes("novaproposta");
  }

  function novaPendencia() {
    props.setNotificacoes("novapendencia");
  }

  useEffect(() => {
    let interval = setInterval(() => {
      console.log("Teste get: modal-root");
      let element = document.getElementById("modal-root");
      if (element) {
        element.style.display = "none";
        if (element.parentNode) {
          element.parentNode.removeChild(element);
        }
        clearInterval(interval);
      } else clearInterval(interval);
    }, 0);

    // console.log(process.env)
    if (props?.user?.tipo?.indexOf(["ADMINISTRADOR", "SUPERVISOR", "OPERADOR"]) < 0)
      inactivityTime();
    window.onbeforeunload = excluirLocks;
    gravarIp();

    // const socket = ws.connect().subscribe("notification");

    // socket.on('message', onMessage)
    // socket.on("nova:proposta", novaProposta);
    // socket.on("posicao:proposta", novaProposta);
    // socket.on("nova:pendencia", novaPendencia);
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Main screen={0} />
        </Route>

        <Route component={PreencherCli} path="/preenchercli/:proposta" />

        <Route exact path="/altera">
          <Main screen={2} />
        </Route>

        <AdminRoute path="/admin" component={Admin} />

        <Route path="/login">
          <Login />
        </Route>

        <Route path="/teste">
          <Refin />
        </Route>

        <Route component={NovaSenha} path="/novaSenha/:email" />

        <Route path="/*">
          <NoMatch title="Erro" />
        </Route>
      </Switch>
    </Router>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    pendencias: state.dados.pendencias
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarUser(novoUser) {
      dispatch(setUser(novoUser));
    },
    setNotificacoes(notificacao) {
      dispatch(setNotificacoes(notificacao));
    },
  };
}

// export default App;
export default connect(mapStateToProps, mapActionToProps)(App);
